//-------------
//- BAR CHART -
//-------------

export var barChartData = {
    type: 'bar',
    data: {
        labels: [],
        datasets: [{
            label: 'الطلبات',
            backgroundColor: 'rgb(255, 181, 62)',
            borderColor: 'rgb(255, 181, 62)',
            pointRadius: false,
            pointColor: 'rgb(255, 181, 62)',
            pointStrokeColor: 'rgb(255, 181, 62)',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgb(255, 181, 62)',
            data: [],
        }, ]
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        datasetFill: false
    }


}



export default { barChartData };