<template>
     <div class="container-fluid">
      <div class="row col-md-11 mx-auto dashboard-cover">
   
              <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="card card-stats">
                <div class="card-header card-header-success card-header-icon">
                  <div class="card-icon">
                    <i class="fa fa-shopping-basket"></i>
                  </div>
                  <p class="card-category">عدد المنتجات</p>
                  <h3 class="card-title">{{DashboardStatistics.productsCount}} </h3>
                </div>
                <div class="card-footer">
                  <!-- <div class="stats">
                    <i class="material-icons">update</i> هم‌اکنون
                  </div> -->
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="card card-stats">
                <div class="card-header card-header-info card-header-icon">
                  <div class="card-icon">
                    <i class="fa fa-group"></i>
                  </div>
                  <p class="card-category">عدد الزبائن</p>
                  <h3 class="card-title">{{DashboardStatistics.clientsCount}} </h3>
                </div>
                <div class="card-footer">
                  <!-- <div class="stats">
                    <i class="material-icons">update</i> هم‌اکنون
                  </div> -->
                </div>
              </div>
            </div>



             <div class="col-md-12 col-lg-12 col-sm-12 mb-custom">
                <div class="white-box">
                    <div class="text-center table-title">
                        <span>إحصائيات بالطلبات</span>
                    </div>


                <canvas id="BarChart" style="min-height: 250px; height: 250px; max-height: 250px; max-width: 100%;"></canvas>

                </div>
             </div>
 

      </div>
     </div>
</template>

<script>
import { mapState} from 'vuex';
import store from '../../store/index';
import { barChartData } from '../../utils/chartjs/chart-data';
export default {
  data(){
    return{
      barChartData:barChartData,
    }
  },
  methods:{
    createChart(chartId, chartData) {
       const ctx = document.getElementById(chartId);
          // This will get the first returned node in the jQuery collection.
      var areaChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options
      })
    },
  },
  computed:{
      ...mapState({
           DashboardStatistics: state => state.admin.dashboard.DashboardStatistics,     
        }),
    }, 
    mounted(){
      if(this.DashboardStatistics.messageCode == '00'){
        this.barChartData.data.datasets[0].data=this.DashboardStatistics.salesCount;
        this.barChartData.data.labels=this.DashboardStatistics.statusName;
 
     }
    this.createChart('BarChart',this.barChartData);
    },
  beforeRouteEnter(to, from, next) {
       store.commit('admin/PleaseStopLoading');
        store.dispatch('admin/getDashboardStatisticsList',{})
        .then((response) => {
             next(true);
        })
		.catch((error) => {
			next(false);
      });
    }, 
}
</script>

<style>
.dashboard-cover{
    background: #ededed;
    box-shadow: #ededed;
    box-shadow: 1px 1px 4px 3px rgb(0 0 0 / 10%);
    padding: 16px;
}
</style>